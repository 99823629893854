import React, { useEffect } from "react";

import Header from './Header';
import Form from './Form';

//import { disableScroll, enableScroll } from '../scrollFuncs';

const ContactPage = (props) => {

   

    return (
        <div id="contact" style={{ position: 'relative' }} >
            <Header />
            <Form />
        </div>
    )
}

export default ContactPage;