import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';

import ContactForm from '../ContactForm';

import img from "../../assets/products/theia/theia-1.png";
import img2 from "../../assets/products/theia/theia-2.png";
import img3 from "../../assets/products/theia/theia-3.png";

const scrollWithOffset = (eleID) => {
    setTimeout(() => {
        console.log("Scrolling to", eleID);
        if (eleID === "top") {
            window.scrollTo({ top: 0, behavior: "smooth" })
            return;
        }
        const yCoordinate = document.getElementById(eleID).getBoundingClientRect().top + window.pageYOffset;
        const navbar = document.getElementsByClassName("head-container")[0];
        const yOffset = -navbar.offsetHeight;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }, 75);
}

const Mount = (props) => {

    const setOne = <>
        <div className="left-set">
            <p>TransAstra's Theia algorithm enhances a telescopes' ability to detect faint moving objects, outperforming conventional methods by 100 times.</p>
            <p>With no prior knowledge of an object's trajectory, Theia detects spacecraft or asteroids that would otherwise be invisible.</p>
            <p>Our <Link to ='/capabilities/#Detect' onClick={() => scrollWithOffset("detecting")} >Sutter technology</Link> can boost the performance of many Space Domain Awareness (SDA) systems including ground telescopes, star trackers or specialized space telescopes.</p>
        </div>
        <div className="right-set" >
            <div style={{ "display": "flex", "flexDirection": "column" }} >
                <img style={{ "padding": "1em 0" }} src={img} ></img>
                <img style={{ "padding": "1em 0" }} src={img2} ></img>
                <img style={{ "padding": "1em 0" }} src={img3} ></img>
            </div>

        </div>
    </>

    return (
        <div id="theia" className="capa-container contactable" style={{ paddingTop: '3em' }}>
            <h1>THEIA™</h1>
            <div className="set-container" >
                {setOne}
            </div>

            <div className="button" >
                <p onClick={() => { props.openForm(props.formID) }} >Click for Quote</p>
            </div>

            <div id={props.formID} className="hidden">
                <ContactForm formID={props.formID} useShader={true} title="Theia Inquiry" fields={props.fields} />
            </div>
        </div>
    )
}

export default Mount;