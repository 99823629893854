import React from "react";

require('../../css/capabilities/customers.css');

const Customers = (props) => {
    let key = 0;
    return (
        <div className="customers-container" >
            {/* <div>Customers</div> */}
            {props.customers.map((customer) => {
                return <div key={key++} >
                    <img src={customer} ></img>
                </div>
            })}
        </div>
    )
}

export default Customers;