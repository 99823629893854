import React from "react";

import img from "../../assets/news/articles/gold_rush.png";
import img2 from "../../assets/news/podcasts/cosmos_safari.png";
import img3 from "../../assets/news/podcasts/futurized.png";
import img4 from "../../assets/news/podcasts/fos.png";
import img5 from "../../assets/news/podcasts/Space_Things.png";
import img6 from "../../assets/news/podcasts/Orbital_Mechanics.png";
import img7 from "../../assets/news/podcasts/Newspace.png";
import img8 from "../../assets/news/podcasts/space_show.png";
import img9 from "../../assets/news/podcasts/Futurati_Podcast.png";
import img10 from "../../assets/news/podcasts/space_strategy.png";
import img11 from "../../assets/news/podcasts/planetary_radio.png";
import img12 from "../../assets/news/podcasts/WSJ.png";
import img13 from "../../assets/news/podcasts/TomStyleShow.png";
import img14 from "../../assets/news/podcasts/cosmos_safari.png";


const Featured = (props) => {
    return (
        <div id='featured' className="news-page capa-container" >
            <h1>Featured</h1>

            <div className="img-container" ><img src={img2}></img></div>
            <a href='https://www.cosmossafari.com/podcast/episode/7a1d2b2a/space-junk-recycling-asteroid-mining-and-engineering-the-future-of-space-presented-by-celestron'><h2>Space Junk Recycling, Asteroid Mining, & Engineering the Future of Space</h2></a>
            <p>May 21, 2024</p>
            <p>Cosmo Safari</p>
            <p>Podcast</p>

            <div className="img-container" ><img src={img}></img></div>
            <a href='https://www.newswire.com/news/transastra-awarded-first-defense-department-contract-for-flytrap-22227603#:~:text=Through%20civil%2C%20commercial%2C%20and%20defense,Founder%20and%20CEO%20of%20TransAstra.'><h2>TransAstra Awarded First Defense Department Contract for FlyTrap, Following Success with NASA</h2></a>
            <p>January 24, 2024</p>
            <p>Chronicle-Tribune</p>
            <p>Article</p>

            <div className="video-container" style={{ marginTop: "20px" }}><iframe
                            src={"https://www.youtube.com/embed/tKOL2Y5MjJM"}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                        </iframe></div>
            <a href='https://www.youtube.com/watch?v=tKOL2Y5MjJM&t=2s'><h2>Mini Bee Ground Optical Mining Demonstration</h2></a>
            <p>October 5, 2022</p>
            <p>Video</p>


        </div>
    )
}

export default Featured;