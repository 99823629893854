import React, { useEffect, useState, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";

import logo from '../../assets/navbars/header-logo.png';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';

import rawData from '../sections.json';
import locations from '../navLocations.json'

require('../../css/mainStyles/header.css');
require('../../css/mainStyles/headerMobile.css');

const data = Object.values(rawData);
console.log(data);

const Header = (props) => {

    let link = useLocation();
    const linkSublocation = link.hash.replaceAll('#', '').replaceAll('-', ' ');
    const pageSublocation = useRef(linkSublocation).current;

    const scrollToLink = () => {
        if (pageSublocation?.length < 1) return;
        const eleID = locations[pageSublocation];
        console.log("Page sub location:", pageSublocation);
        console.log("eleID:", eleID);
        if (eleID) {
            scrollWithOffset(eleID);
            return;
        }
    }

    useEffect(() => {
        scrollToLink();
    }, []);


    const scrollWithOffset = (eleID) => {
        setTimeout(() => {
            const eles = document.getElementsByClassName("dropdown-content");
            for (let i = 0; i < eles.length; i++) {
                eles[i].classList.add("hidden");
            }
            setTimeout(() => {
                for (let i = 0; i < eles.length; i++) {
                    eles[i].classList.remove("hidden");
                }
            }, 50)
            console.log("Scrolling to", eleID);
            if (eleID === "top") {
                window.scrollTo({ top: 0, behavior: "smooth" })
                return;
            }
            const yCoordinate = document.getElementById(eleID).getBoundingClientRect().top + window.pageYOffset;
            const navbar = document.getElementsByClassName("head-container")[0];
            const yOffset = -navbar.offsetHeight;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }, 75);
    }

    const desktopVersion = useMemo(() => (
        <DesktopHeader words={data} scrollWithOffset={scrollWithOffset} logo={logo} ></DesktopHeader>
    ), []);

    const mobileVersion = useMemo(() => (
        <MobileHeader words={data} scrollWithOffset={scrollWithOffset} logo={logo} ></MobileHeader>
    ), []);

    let dispState = props.shouldLoadDesktop ? desktopVersion : mobileVersion;
    return (
        <>
            {dispState}
        </>
    );
};

export default Header;