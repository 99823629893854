export const disableMobileScreen = () => {
    if (!document.getElementById("header-mobile").classList.contains("hidden")) {
        document.getElementById("header-mobile").classList.toggle("hidden");
    }
    document.getElementById("mobile-button").classList.remove("change");
}

export const disablePriorFlex = () => {
    document.getElementsByClassName("chevron-transformed")?.[0]?.classList.remove("chevron-transformed");
    let oldContainer = document.getElementsByClassName("blue-back")[0];
    let oldChildrenContainer = document.getElementsByClassName("flex")[0];
    if (oldContainer) oldContainer.classList.toggle("blue-back");
    if (oldChildrenContainer) {
        oldChildrenContainer.classList.toggle("flex");
        oldChildrenContainer.style.maxHeight = "0px";
    }
}

export const enableCurrentFlex = (ev) => {
    const container = ev.currentTarget;
    const childrenContainer = container.childNodes?.[1];
    container.classList.toggle("blue-back");
    childrenContainer?.classList.toggle("flex");
    const flex = document.getElementsByClassName("flex")[0];
    if (flex) flex.style.maxHeight = flex.childNodes.length * 2.5 + "em";
    container.childNodes[0]?.childNodes[1]?.childNodes[0].classList.toggle("chevron-transformed");
}