import React from "react";

import Detecting from "./Detecting";
import Maneuvering from "./Maneuvering";
import Capturing from "./Capturing";
import Processing from "./Processing";

import Sidebar from "../Sidebar";

import Customers from "./Customers";

import nasaLogo from '../../assets/logos/nasa-logo.png';
import spaceForceLogo from '../../assets/logos/space-force-logo.png';
import niacLogo from '../../assets/logos/niac-logo.png';
import YCLogo from '../../assets/logos/YC-logo-black.png';

const CapabilitiesPage = (props) => {
    return (
        <div className="content-container" >
            <Sidebar sections={props.data} id="capa-sidebar" shouldLoadDesktop={props.shouldLoadDesktop} />
            <div className="content-sub-container">
                <Detecting />
                <Maneuvering />
                <Capturing />
                <Processing />
                <Customers customers={[nasaLogo, niacLogo, spaceForceLogo, YCLogo]} />
            </div>
        </div>
    )
}

export default CapabilitiesPage;