import React, { useState } from "react";

require('../../css/company/history.css');

import data from './companyEvents.json';
// console.log("data", data);

import img from "../../assets/company/history/Worker_Bee_Right_Facing.png";
import img2 from "../../assets/company/history/Honey Bee Asteroids.001.jpg";
import img3 from "../../assets/company/history/optical mining with light effects.jpg";
import img4 from "../../assets/company/history/Apis Spacecraft.png";
import img5 from "../../assets/company/history/white_sands_grab.png";
import img8 from "../../assets/logos/YC-logo-short.png";
import img6 from "../../assets/company/history/IMG_2281.jpg";
import img7 from "../../assets/company/history/Xerxes Mount w Star Layer.jpg";

import HistoryEvent from './HistoryEvent';

const images = {
    key: [
        img,
        img2,
        img3,
        img4,
        img5,
        img8,
        img6,
        img7,
        img7,
    ],
    full: [
        img,
        img2,
        null,
        img5, // nov 2015
        null,
        null,
        null,
        img3,
        null,
        null,
        img4, // nov 2020
        null,
        null,
        null,
        null,
        null,
        null,
        img8,
        null,
        null,
        img6, // jan 2022
        null,
        img7,
        null,
        null,
        null,
        null,
        null,
        null,
        img7,
        img7,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
    ]
}

const History = (props) => {

    const [dispFull, changeDispFull] = useState(true);

    let events;
    if (dispFull) {
        events = data.full;
    } else events = data.key;
    let imgs;
    if (dispFull) {
        imgs = images.full;
    } else imgs = images.key;

    let buttonText = "See More";
    if (dispFull) buttonText = "See Less";

    let key = -1;
    let left = false;

    return (
        <div id="history" >
            <h1>HISTORY</h1>
            <div className="timeline-container" >
                <div className="bar" ></div>
                {events.map((item) => {
                    ++key
                    if (props.shouldLoadDesktop) left = !left;
                    return <HistoryEvent shouldLoadDesktop={props.shouldLoadDesktop}
                        dispFull={dispFull} img={imgs[key]} key={key} item={item} left={left} />;
                })}
            </div>
            {/* <div className='button'>
                <p onClick={() => changeDispFull(!dispFull)} >{buttonText}</p>
            </div> */}
        </div>
    )
}

export default History;