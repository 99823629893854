import React from "react";

const HistoryEvent = (props) => {
    // const img = require(`${props.item.img}`);
    let top = props.top;
    if (!props.shouldLoadDesktop) top = 0;
    return (
        <>
            {props.left ?
                <div style={{ top: `${top}em` }} className="node left" >
                    <div className="text" >
                        <h2>{props.item.title}</h2>
                        <p>{props.item.desc}</p>
                    </div>
                    <div className="flex-column" >
                        <div className="date" ><p>{props.item.date}</p></div>
                        {
                            props.shouldLoadDesktop && props.img ?
                                <div className="img" ><img src={props.img} ></img></div>
                                : <></>
                        }
                    </div>
                    <div className="dot" ></div>
                </div>
                :
                <div style={{ top: `${top}em` }} className="node right" >
                    <div className="dot" ></div>
                    <div className="flex-column" >
                        <div className="date" ><p>{props.item.date}</p></div>
                        {
                            props.shouldLoadDesktop && props.img ?
                                <div className="img" ><img src={props.img} ></img></div>
                                : <></>
                        }
                    </div>
                    <div className="text" >
                        <h2>{props.item.title}</h2>
                        <p>{props.item.desc}</p>
                    </div>
                </div>
            }
        </>
    )
}

export default HistoryEvent;