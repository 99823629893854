import React, { useEffect, useRef } from "react";

import locations from './navLocations.json';

const pruneHref = () => {
    const base = window.location.href;

    // console.log("base href:", base);

    for (let i = base.length - 1; i > 0; --i) {
        if (base[i] === '/') {
            return base.slice(0, i + 1);
        }
    }

    return "";
}

const Sidebar = (props) => {
    const sections = props.sections;

    const scrollPos = useRef(window.scrollY);
    const prior = useRef(-1);

    const updateSelection = (id) => {
        // console.log("Jumping to section:", id);
        // console.log("current:", prior.current);
        if (!id) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return;
        }
        let header = document.getElementById("header-container");
        if (!header) header = document.getElementById("header-container-mobile");
        const yOffset = header.offsetHeight;
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.scrollY - yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }

    const handleScroll = () => {
        scrollPos.current = window.scrollY;
        checkScrollPos();
    };

    const checkScrollPos = () => {
        let header = document.getElementById("header-container");
        if (!header) header = document.getElementById("header-container-mobile");
        const hRect = header.getBoundingClientRect();
        if (document.getElementById(sections[0].id).getBoundingClientRect().top > hRect.height + 50) {
            if (prior.current == -1) return;
            prior.current = -1;
            if (props.shouldLoadDesktop) {
                const lastSelection = document.getElementsByClassName("selected");
                if (lastSelection.length) lastSelection[0].classList.remove("selected");
                const pruned = pruneHref();
                window.history.replaceState({}, "", pruned);
            } else {
                selectSectionMobile(0);
            }
            return;
        }
        for (let i = 0; i < sections.length; i++) {
            const rect = document.getElementById(sections[i].id).getBoundingClientRect();
            const offset = (rect.height / 2) + hRect.height;
            let x = 0;
            if (props?.offsetElementID)
                x = document.getElementsByClassName(props.offsetElementID)[0].getBoundingClientRect().height;
            if (rect.top - offset < 0 && (rect.bottom - offset) + x > 0) {
                if (prior.current == i) return;
                console.log("Selecting section: ", sections[i].id);
                props.shouldLoadDesktop ? selectSection(sections[i]) : selectSectionMobile([i + 1]);
                prior.current = i;
                return;
            }
        }
    }

    // props.shouldloaddesktop is snapshotting rn
    // fix

    const selectSection = (section) => {
        const lastSelection = document.getElementsByClassName("selected");
        if (lastSelection.length > 0) lastSelection[0].classList.remove("selected");
        window.history.replaceState({}, "", `#${locations[section.id]}`);
        document.getElementById(section.id.toUpperCase()).classList.add("selected");
    }
    const selectSectionMobile = (i) => {
        if (i > 0) {
            const location = locations[sections[i - 1].id];
            window.history.replaceState({}, "", `#${location}`);
        } else {
            const pruned = pruneHref();
            window.history.replaceState({}, "", pruned);
        }
        const section = sections?.[i];
        const p = document.getElementById("section-name");
        if (!section) {
            p.innerHTML = "Return to top";
        } else {
            p.innerHTML = section.title;
            const arrow = document.createElement("i");
            arrow.classList.add("arrow");
            p.appendChild(arrow);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
        if (!props.shouldLoadDesktop) {
            selectSectionMobile(0);

            setTimeout(() => {
                const ele = document.getElementsByClassName("mobile-sidebar")[0];
                const h = ele.getBoundingClientRect().height;
                document.getElementById("footer-container").style.paddingBottom = `calc(2em + ${h}px)`;
            }, 100);
        }
        return () => {
            window.removeEventListener('scroll', handleScroll);
            document.getElementById("footer-container").style.paddingBottom = '2em';
        };
    }, []);

    let dKey = 0;
    const desktopDisplay = (
        <div style={{ width: '12em' }} >
            <div id={props.id} className="sidebar" >
                {sections.map((section) => {
                    return <div id={section.id.toUpperCase()} className="text"
                        key={++dKey} onClick={() => updateSelection(section.id)} >
                        {section.title}
                    </div>
                })}
            </div>
        </div>
    )
    const mobileDisplay = (
        <div id={props.id} className="mobile-sidebar" onClick={() => updateSelection(sections?.[prior.current + 1]?.id)} >
            <p id="section-name" ><i className="arrow" ></i></p>
        </div>
    )

    const disp = props.shouldLoadDesktop ? desktopDisplay : mobileDisplay;

    return (
        <>
            {disp}
        </>
    )
}

export default Sidebar;