import React, { useEffect } from "react";

import img from '../../assets/nebulas/Nebula-01.png';

const Header = (props) => {

    useEffect(() => {
        setTimeout(() => {
            const imgParent = document.getElementById('contact-img-container');
            const img = document.getElementById('contact-img');
            const imgOffset = img.getBoundingClientRect().width - imgParent.getBoundingClientRect().width;
            if (imgOffset > 0) {
                // img.style.transform = `translateX()`;
                img.style.transform = `translateX(-${imgOffset / 2}px)`;
            }
        }, 50);
    }, [])


    return (
        <div id="contact-img-container" >
            <img id="contact-img" src={img} ></img>
        </div>
    )
}

export default Header;