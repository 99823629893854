import React from "react";
import { Link } from 'react-router-dom';

const DesktopHeader = (props) => {
    let key1 = 69;
    let key2 = 420;
    return (
        <div id="header-container" className="head-container" >
            <div className="header-logo">
                <Link to="/" onClick={() => props.scrollWithOffset("top")}><img src={props.logo} ></img></Link>
            </div>
            <nav id="header" role="navigation" >
                {props.words.map((section) => (
                    <div className="nav-section" key={key1++} >
                        <Link className={section.dropdowns ? "link dropbtn" : "link dropbtn emptydropbtn"} to={"/" + section.link + "/"}
                            onClick={() => props.scrollWithOffset("top")}>
                            {section.name}
                        </Link>
                        {section.dropdowns && <div className="dropdown-content" >
                            {section.dropdowns.map((dropdown) => {
                                const linkName = dropdown.title.replace(/ /g, "-");
                                return (<div key={key2++}>
                                    <Link className="link" to={"/" + section.link + "/#" + linkName} onClick={() => props.scrollWithOffset(dropdown.id)} >
                                        {dropdown.title}
                                    </Link>
                                    {/* <Link className="link" smooth to={"/" + section.link + "/#" + dropdown[1]} scroll={el => props.scrollWithOffset(el)} >{dropdown[0]}</Link> */}
                                </div>
                                )
                            })}
                        </div>
                        }
                    </div>
                ))}
            </nav>
        </div>
    )

}

export default DesktopHeader;