import React from "react";

// require('../../css/products/main.css');

import Header from './Header';

import ContentContainer from './ContentContainer';

const ProductsPage = (props) => {
    return (
        <div id="products" >
            {/* <Header shouldLoadDesktop={props.shouldLoadDesktop} linkID={props.data.dropdowns[0].id} /> */}
            <ContentContainer data={props.data.dropdowns} shouldLoadDesktop={props.shouldLoadDesktop} />
        </div>
    )
}

export default ProductsPage;