import React from "react";
import { Link } from 'react-router-dom';

import ContactForm from '../ContactForm';


require('../../css/products/TKO.css');

import img from "../../assets/products/TKO/TKO-main-white.png";

import TKO1 from "../../assets/products/TKO/TKO-1.png";
import TKO2 from "../../assets/products/TKO/TKO-field-2.png";
import TKO3 from "../../assets/products/TKO/TKO-night-3png.png";


const scrollWithOffset = (eleID) => {
    setTimeout(() => {
        console.log("Scrolling to", eleID);
        if (eleID === "top") {
            window.scrollTo({ top: 0, behavior: "smooth" })
            return;
        }
        const yCoordinate = document.getElementById(eleID).getBoundingClientRect().top + window.pageYOffset;
        const navbar = document.getElementsByClassName("head-container")[0];
        const yOffset = -navbar.offsetHeight;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }, 75);
}

const Observatory = (props) => {
    return (
        <div id="observatory" className="capa-container contactable">
            <h1 >SUTTER TURNKEY OBSERVATORY (TKO)</h1>
            <p style={{ padding: "1em 0 0.2em" }} >Sutter TKO is a modular, rapidly deployable sky survey system for space domain awareness in MEO, GEO and beyond. 
            TransAstra’s Sutter Ground Network consists of three (3) high performance telescope systems in CA, AZ, AUS which outperform every other commercial 
            provider in cislunar observations. </p>
            <p style={{ padding: "0.2em 0" }} >TKO can work at the customer's chosen location; there is no need for an observatory. </p>
            <p style={{ padding: "0.2em 0" }} >TransAstra's Sutter Turnkey Observatory (Sutter TKO) provides our customers with
                unparalleled performance in all sky moving object detection at a fraction
                of the cost of traditional telescopes and observatories.</p>
            <p style={{ padding: "0.2em 0 1em" }} >When equipped with optional <Link to ='/products/#Theia' onClick={() => scrollWithOffset("theia")} >Theia</Link> software, TKO is 100s of times more sensitive and becomes a complete
                autonomous observe and compute system.</p>

            <div className="big-img" ><img src={img} ></img></div>

            <ul className="blue-border">
                <li>Each TKO module houses 6 Celestron RASA 11 Telescopes</li>
                <li>Observatory enclosure opens and closes autonomously</li>
                <li>Computing systems for image processing</li>
                <li>High speed global internet included</li>
                <li>Requires only electrical power and a relatively flat surface (solar array and battery as an option if power not available)</li>
            </ul>

            <div className="img-set" >
                <div>
                    <div><img src={TKO1} ></img></div>
                    <p>Easy to deliver</p>
                </div>
                <div>
                    <div><img src={TKO2} ></img></div>
                    <p>Fast to setup</p>
                </div>
                <div>
                    <div><img src={TKO3} ></img></div>
                    <p>Simple to operate</p>
                </div>
            </div>

            <div className="button" >
                <p onClick={() => { props.openForm(props.formID) }} >Click for Quote</p>
            </div>

            <div id={props.formID} className="hidden">
                <ContactForm formID={props.formID} useShader={true} title="Sutter TKO Inquiry" fields={props.fields} />
            </div>

        </div>
    )
}

export default Observatory;