import React, { useState, useRef } from "react";

import img from "../../assets/capabilities/process/process-1.png";

import SelectionBar from "../SelectionBar";

const Processing = (props) => {

    const [sectionNum, updateSectionNum] = useState(0);

    const setOne = <>
        <div className="left-set" >
            <p>Our NASA-funded and reviewed mission studies show a near-term $100B commercial potential for asteroid mining.</p>
        </div>
        <div className="right-set" >
            <img src={img}></img>
        </div>
    </>

    const setTwo = <>
        <div className="left-set" >
            <p>TransAstra demonstrated Optical Mining of a synthetic asteroid in White Sands, New Mexico in 2015.</p>
        </div>
        <div className="right-set" >
            <iframe
                src="https://www.youtube.com/embed/e5GHsIJCS4M"
                title='video'
            />
        </div>

    </>

    const sets = [setOne, setTwo]
    const headers = ["Apis™ Mission Architecture", "White Sands Demonstration"]

    return (
        <div id="processing" className="capa-container" >
            <h1>Optical Mining™</h1>
            <p className="desc" >Earth-based mining techniques will not work on asteroids, but Optical Mining will. Optical Mining uses focused sunlight to efficiently extract resources from asteroids and space debris.</p>
            <h1 className="sub-header" >{headers[sectionNum]}</h1>
            <div className="set-container">
                {sets[sectionNum]}
            </div>
            <SelectionBar num={sets.length} sectionNum={sectionNum} updateSectionNum={updateSectionNum} />
        </div>
    )
}

export default Processing;