import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';

import imgMain from "../../assets/company/facilities/facilities-main.png";
import img from "../../assets/company/facilities/lab1.png";
import img2 from "../../assets/company/facilities/lab2.png";
import img3 from "../../assets/company/facilities/lab3.png";

require('../../css/company/facilities.css');

const scrollWithOffset = (eleID) => {
    setTimeout(() => {
        console.log("Scrolling to", eleID);
        if (eleID === "top") {
            window.scrollTo({ top: 0, behavior: "smooth" })
            return;
        }
        const yCoordinate = document.getElementById(eleID).getBoundingClientRect().top + window.pageYOffset;
        const navbar = document.getElementsByClassName("head-container")[0];
        const yOffset = -navbar.offsetHeight;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }, 75);
}

const Facilities = (props) => {
    return (
        <div id="facilities" className="capa-container">
            <h1>The Hive</h1>
            <div><img src={imgMain} ></img></div>
            <p className="centered" >The Hive, 13539 Desmond St, San Fernando, CA 91331</p>
            <div className="images" >
                <div><img src={img} ></img></div>
                <div><img src={img2} ></img></div>
                <div><img src={img3} ></img></div>
            </div>
            <p>The Hive is the 7,000 ft² facility we use for developing, integrating, and
                testing prototype assemblies and spaceflight hardware, including the <Link to ='/capabilities/#Maneuver' onClick={() => scrollWithOffset("maneuvering")} >Omnivore™ </Link>
                thruster, <Link to ='/capabilities/#Capture' onClick={() => scrollWithOffset("capturing")} >Flytrap™ </Link>capture bags, <Link to ='/capabilities/#Detect' onClick={() => scrollWithOffset("detecting")} >Sutter™ </Link> telescope systems, and engineering
                for the <Link to ='/capabilities/#Maneuver' onClick={() => scrollWithOffset("maneuvering")} >Worker Bee™ </Link>  flight system.
                Designed for agile engineering The Hive is equipped with machine tools,
                rapid prototyping systems, large vacuum systems, optical benches and extensive
                electrical test and development gear.</p>
        </div>
    )
}

export default Facilities;