import React, { useState, useRef, useEffect } from "react";

import ContactForm from '../ContactForm';

// require('../../css/contact/form.css');

export const options = [
    { name: "Sales", num: 0, html: "mailto:sales@transatra.com" },
    { name: "Career", num: 1, html: "mailto:careers@transatra.com" },
    { name: "Media", num: 2, html: "mailto:media@transatra.com" },
    { name: "Other", num: 3, html: "mailto:missions@transatra.com" }
]

const fields = [
    { type: "Subject", html: "input" },
    { type: "Name", html: "input" },
    { type: "Organization", html: "input" },
    { type: "Email", html: "input" },
    { type: "Phone", html: "input" },
    { type: "Message", html: "textarea" },
]


const title = "Reason for contact"

const Form = (props) => {


    return (
        <>
            <ContactForm formID="main" title={title} options={options} fields={fields} />
        </>
    )
}


export default Form;