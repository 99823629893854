import React, { useMemo } from "react";
require('../../css/splash/header.css');
require('../../css/splash/headerMobile.css');
require('../../css/mainStyles/pageHeader.css')

import video from '../../assets/splash/splash-header-video.mp4'
// import compressedVideo from '../../assets/splash/MiniBee Frontpage Video Mobile.mp4'
import compressedVideo from '../../assets/splash/splash-header-video.mp4'

const words = {
    h1: "ENGINEERING THE FUTURE OF SPACE",
    linkText: "Watch Our Story",
    linkTo: "/media/videos/The TransAstra Story: How We Are Engineering the Future of Space",
}

const scrollToNav = () => {
    let yOffset = document.getElementById("header-container")?.offsetHeight;
    if (!yOffset) yOffset = document.getElementById("header-container-mobile").offsetHeight;
    const element = document.getElementById('splash-capabilities');
    const y = element.getBoundingClientRect().top + window.scrollY - yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' });
}

const Header = (props) => {

    console.log(props.shouldLoadDesktop);

    const vid = <div className="video" ><video id="mini-bee-splash-video" src={props.shouldLoadDesktop ? video : compressedVideo} autoPlay muted playsInline loop ></video></div>

    const innerContent = <>{vid}
        <div className="text-wrapper" >
            <h1 >{words.h1}</h1>
            <a className="link" target="_blank" href="https://www.youtube.com/watch?v=2ChgtAy-a1Y" >
                {words.linkText}
                {/* <p></p> */}
            </a>
        </div>
        {props.shouldLoadDesktop ? <div className='arrow' onClick={scrollToNav} ></div> : <></>}
        <div className="gradient" ></div>
    </>

    const component = useMemo(() => {
        return <div className="page-header splash-header" id={props.shouldLoadDesktop ? "splash-header" : "splash-header-mobile"} >
            {innerContent}
        </div>
    }, [props.shouldLoadDesktop])

    return (
        <>
            {component}
        </>
    )
}

export default Header;