import React from "react";

import img from "../../assets/news/podcasts/Your_Space_Journey.png";
import img2 from "../../assets/news/podcasts/Eliana_Sheriff.png";
import img3 from "../../assets/news/podcasts/futurized.png";
import img4 from "../../assets/news/podcasts/fos.png";
import img5 from "../../assets/news/podcasts/Space_Things.png";
import img6 from "../../assets/news/podcasts/Orbital_Mechanics.png";
import img7 from "../../assets/news/podcasts/Newspace.png";
import img8 from "../../assets/news/podcasts/space_show.png";
import img9 from "../../assets/news/podcasts/Futurati_Podcast.png";
import img10 from "../../assets/news/podcasts/space_strategy.png";
import img11 from "../../assets/news/podcasts/planetary_radio.png";
import img12 from "../../assets/news/podcasts/WSJ.png";
import img13 from "../../assets/news/podcasts/TomStyleShow.png";
import img14 from "../../assets/news/podcasts/cosmos_safari.png";


const Podcasts = (props) => {
    return (
        <div id='podcasts' className="news-page capa-container" >
            <h1>Podcasts</h1>

            <div className="img-container" ><img src={img14}></img></div>
            <a href='https://www.cosmossafari.com/podcast/episode/7a1d2b2a/space-junk-recycling-asteroid-mining-and-engineering-the-future-of-space-presented-by-celestron'><h2>Space Junk Recycling, Asteroid Mining, & Engineering the Future of Space</h2></a>
            <p>May 21, 2024</p>

            <div className="img-container" ><img src={img13}></img></div>
            <a href='https://www.youtube.com/watch?v=sDajjtSRBJ4&t=1s'><h2>Tom Style - Dr. Joel Sercel on Asteroid Gold Rush and TransAstra's Space Breakthroughs!</h2></a>
            <p>November 21, 2023</p>

            <div className="img-container" ><img src={img}></img></div>
            <a href='https://www.youtube.com/watch?v=H7You3GbsrI'><h2>Your Space Journey Podcast - Asteroid Mining: Engineering the Future of Space</h2></a>
            <p>June 19, 2023</p>

            <div className="img-container" ><img src={img2}></img></div>
            <a href='https://www.youtube.com/watch?v=icO3pu3Mcsk'><h2>Eliana Sheriff Youtube Podcast - Asteroid Mining</h2></a>
            <p>November 29, 2022</p>

            <div className="img-container" ><img src={img3}></img></div>
            <a href='https://www.futurized.org/what-tech-breakthroughs-are-needed-for-asteroid-mining/'><h2>Futurized Podcast - What Tech Breakthroughs Are Needed For Asteroid Mining?</h2></a>
            <p>November 01, 2022</p>

            <div className="img-container" ><img src={img4}></img></div>
            <a href='https://youtu.be/OD12SoVv7Ac'><h2>Future of Space Podcast - Joel Sercel Interview</h2></a>
            <p>October 27, 2022</p>

            <div className="img-container" ><img src={img5}></img></div>
            <a href='https://shows.acast.com/spaceandthings/episodes/stp113-how-to-use-an-asteroid-to-save-humanity-with-joel-ser'><h2>Space and Things - How to Use and Asteroid to Save Humanity - with Joel Sercel</h2></a>
            <p>October 26, 2022</p>

            <div className="img-container" ><img src={img6}></img></div>
            <a href='https://theorbitalmechanics.com/show-notes/joel-sercel'><h2>The Orbital Mechanics</h2></a>
            <p>October 25, 2022</p>

            <div className="img-container" ><img src={img7}></img></div>
            <a href='https://explorenewspace.com/blog/how-to-fuel-up-in-space'><h2>Newspace Podcast - Fueling Up in Space</h2></a>
            <p>October 12, 2022</p>

            <div className="img-container" ><img src={img8}></img></div>
            <a href='https://explorenewspace.com/blog/how-to-fuel-up-in-space'><h2>The Space Show with Joel Sercel</h2></a>
            <p>October 07, 2022</p>

            <div className="img-container" ><img src={img9}></img></div>
            <a href='https://podcasts.apple.com/au/podcast/ep-101-asteroid-mining-and-the-future-of-space-joel-sercel/id1541051806?i=1000577760954'><h2>Futurati Podcast - Asteroid Mining and the Future of Space | Joel Sercel</h2></a>
            <p>August 29, 2022</p>

            <div className="img-container" ><img src={img10}></img></div>
            <a href='https://www.afpc.org/news/podcasts/media/space-strategy-episode-12-there-is-gold-in-them-hills-a-deep-dive-into-asteroid-mining-space-logistics'><h2>American Foreign Policy Council - "There is Gold in Them Hills!": A Deep Dive Into Asteroid Mining & Space Logistics</h2></a>
            <p>June 16, 2021</p>

            <div className="img-container" ><img src={img11}></img></div>
            <a href='https://www.planetary.org/planetary-radio/1104-2020-joel-sercel'><h2>Planetary Radio - A Return to Asteroid Mining and Digging Into Space Ethics with Joel Sercel</h2></a>
            <p>November 4, 2020</p>

            <div className="img-container" ><img src={img12}></img></div>
            <a href='https://www.wsj.com/podcasts/wsj-the-future-of-everything/the-future-of-everything-festival-when-humans-invade-space/6F506039-FCBB-49DB-B5D9-3C236B1FB191'><h2>Wall St. Journal - The Future of Everything</h2></a>
            <p>May 16, 2018</p>

        </div>
    )
}

export default Podcasts;