import React from "react";

import Header from "./Header";
import CapabilitiesSection from "./Capabilities";

const SplashPage = (props) => {
    return (
        <div>
            <Header shouldLoadDesktop={props.shouldLoadDesktop} />
            <CapabilitiesSection shouldLoadDesktop={props.shouldLoadDesktop} />
        </div>
    )
}

export default SplashPage;