import React from "react";

import img from "../../assets/news/articles/removal_awareness.png";
import img2 from "../../assets/company/observatories/observatories-map.png";
import img3 from "../../assets/company/observatories/observatories-3.png";
import img4 from "../../assets/company/observatories/observatories-2.jpg";
import img5 from "../../assets/company/observatories/observatories-4.jpg"

require('../../css/company/observatories.css');

const Observatories = (props) => {
    return (
        <div id="observatories" className="capa-container" >
            <h1>Observatories</h1>
            <h2>Today we operate our Sutter telescope systems at observatories in the Arizona desert, the Sierra Nevada mountains in California, and Springbrook Research Obserbatory in Australia. Our 4th and 5th systems are under development for deployment soon after.</h2>
            <div className="row">
                <div>
                    <div><img src={img} ></img></div>
                    <p>Sierra Remote Observatory near Yosemite, California</p>
                </div>
                <div>
                    <div><img src={img4} ></img></div>
                    <p>Winer Observatory near Tucson, Arizona</p>
                </div>
                <div>
                    <div><img src={img5} ></img></div>
                    <p>Springbrook Research Observatory, Australia</p>
                </div>
            </div>
            <div className="map" ><img src={img2} ></img></div>
            <p>Locations of Observatories</p>
        </div>
    )
}

export default Observatories;