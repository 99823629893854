import React, { useState, useRef } from "react";

const SelectionBar = (props) => {
    const arrs = []
    for (let i = 0; i < props.num; ++i) arrs.push(i);

    const updateSelection = (e, key) => {
        props.updateSectionNum(key);
    }

    const styles = [];
    for (let i = 0; i < props.num; ++i) {
        if (props.sectionNum === i) {
            styles.push({
                backgroundColor: 'blue',
            })
        } else {
            styles.push({ backgroundColor: 'white' })
        }
    }

    const goLeft = () => {
        let newNum = props.sectionNum - 1;
        if (newNum < 0) newNum = props.num - 1;
        props.updateSectionNum(newNum);
    }
    const goRight = () => {
        let newNum = props.sectionNum + 1;
        if (newNum > props.num - 1) newNum = 0;
        props.updateSectionNum(newNum);
    }

    return (
        <div className="selection-bar" >
            {/* <div className="left arrow" onClick={goLeft} ></div> */}
            {arrs.map((i) => {
                return <div style={styles[i]} className="dot" key={i} onClick={(e) => updateSelection(e, i)} ></div>
            })}
            {/* <div className="right arrow" onClick={goRight} ></div> */}
        </div>
    )
}

export default SelectionBar;