import React, { useState, useRef } from "react";

require('../../css/news/videos.css');

import dataSet from './videoData.json';

const Videos = (props) => {
    let key = -1;
    return (
        <div id='videos' className="capa-container news-page" >
            <h1>Videos</h1>

            {dataSet.map((data) => {
                ++key;
                return (
                    <div key={key} className="video-section" >
                        <div className="video-container"><iframe
                            src={data.embed}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen>
                        </iframe></div>
                        <a href={data.embed}><h2>{data.title}</h2></a>
                        <p>{data.date}</p>
                    </div>
                )
            })}

        </div>
    )
}

export default Videos;