import React from "react";
import { BrowserRouter } from "react-router-dom";

import AppShell from "./AppShell";

const App = (props) => {
    return (
        <div id="pageContainer" >
            <BrowserRouter>
                <AppShell />
            </BrowserRouter>
        </div>
    );
};

export default App;