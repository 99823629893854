import React, { useState, useRef } from "react";

import img from "../../assets/capabilities/maneuver/maneuver-1.png";
import img2 from "../../assets/capabilities/maneuver/maneuver-2.png";
import img3 from "../../assets/capabilities/maneuver/maneuver-3.png";

import SelectionBar from "../SelectionBar";

const Maneuvering = (props) => {

    const [sectionNum, updateSectionNum] = useState(0);

    const setOne = <>
        <div className="left-set" >
            <p>Omnivore solar thermal propulsion rivals the performance of nuclear rockets at a fraction of the cost, without the major safety issues.
            </p>
        </div>
        <div className="right-set" >
            <img src={img}></img>
        </div>
    </>

    const setTwo = <>
        <div className="left-set" >
            <p>Worker Bee hydrogen upper stage unlocks fast deliveries to MEO, GEO, lunar orbits and beyond. </p>

        </div>
        <div className="right-set" >
            <img src={img2}></img>
        </div>
    </>

    const setThree = <>
        <div className="left-set" >
            <p>Deliver a constellation in days, not months. Reduce or eliminate the most expensive part of a satellite - the main propulsion system - and get your constellation into its operational phase months or years ahead of schedule.
            </p>
        </div>
        <div className="right-set" >
            <img src={img3}></img>
        </div>
    </>

    const sets = [setOne, setTwo, setThree]
    const headers = ["Omnivore", "Worker Bee Upper Stage", "Worker Bee Fleets"]

    return (
        <div id="maneuvering" className="capa-container" >
            <h1>WORKER BEE™ ORBITAL TRANSPORT VEHICLE</h1>
            <p className="desc" >Worker Bee is a radical approach to in-space mobility. Worker Bee's powerful and practical propulsion system uses simple concentrated sunlight for power.<br></br><br></br>• 3-10x faster than electric propulsion
                <br></br>• up to 7x more payload than chemical propulsion, and
                <br></br>• can use almost any volatile as propellant including those extracted from asteroids.
            </p>
            <h1 className="sub-header" >{headers[sectionNum]}</h1>
            <div className="set-container">
                {sets[sectionNum]}
            </div>
            <SelectionBar num={3} sectionNum={sectionNum} updateSectionNum={updateSectionNum} />
        </div>
    )
}

export default Maneuvering;