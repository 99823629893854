import React, { useRef } from "react";
import { Link } from 'react-router-dom';
import * as funcs from './MobileFunctions';


const MobileHeader = (props) => {

    // console.log("Loaded mobile header");

    let numClicks = useRef({ id: "", num: 0 }).current;

    const linkClick = (ev, toLink) => {
        mobileButton(ev);
        props.scrollWithOffset(toLink);
    }

    const mobileButton = (ev) => {
        numClicks.id = ""; numClicks.num = 0;
        document.getElementById("header-mobile").classList.toggle("hidden");
        document.getElementById("mobile-button").classList.toggle("change");
        funcs.disablePriorFlex();
        ev.stopPropagation();
    }

    const flexContent = (ev, item) => {
        // console.log(item);
        if (item.dropdowns === null) {
            numClicks.id = ""; numClicks.num = 0;
            document.getElementById("header-mobile").classList.toggle("hidden");
            document.getElementById("mobile-button").classList.toggle("change");
            funcs.disablePriorFlex();
            // props.scrollWithOffset(item.link);
            return;
        }

        const container = ev.currentTarget;
        funcs.disablePriorFlex();

        if (container.id != numClicks.id) {
            numClicks.num = 1;
            numClicks.id = container.id;
            funcs.enableCurrentFlex(ev);
        } else if (numClicks.num === 1) {
            numClicks.id = "";
            numClicks.num = 0;

        }
    }

    let key1 = 0;
    let key2 = 0;
    return (
        <div id="header-container-mobile" className="head-container" >
            <div className="headerLogo">
                <Link onClick={() => funcs.disableMobileScreen()} to="/"><img src={props.logo} ></img></Link>
            </div>
            <div id="mobile-button" className="button" onClick={(ev) => mobileButton(ev)} >
                <div className="bar-1" ></div>
                <div className="bar-2" ></div>
                <div className="bar-3" ></div>
            </div>
            <nav id="header-mobile" role="navigation" className="hidden" >
                <div className="filter" ></div>
                <ul className="dropdown">
                    {props.words.map((section) => (
                        <li id={`dropdown-container-${key1}`} key={key1++} onClick={el => { flexContent(el, section) }} >
                            <div className="dropdown-header-text" >
                                {section.dropdowns === null ?
                                    <Link className={"dropbtn emptydropbtn"} to={"/" + section.link + "/"} >
                                        {section.name}
                                    </Link> :
                                    <><p className={"dropbtn"} >
                                        {section.name}
                                    </p>
                                        {section.dropdowns && <div className="chevron-container" ><p className="chevron"></p></div>}
                                    </>
                                }
                            </div>

                            {section.dropdowns && <ul className="dropdown-content2" >
                                {section.optional ? <li>
                                    <Link onClick={(ev) => linkClick(ev, section.optional.id)} className="link" to={"/" + section.link + "/"} >
                                        {section.optional.title}
                                    </Link>
                                </li> : <></>}
                                {section.dropdowns.map((dropdown) => {
                                    const linkName = dropdown.title.replace(/ /g, "-");
                                    ++key2;
                                    return (
                                        <li key={key2}>
                                            <Link onClick={(ev) => linkClick(ev, dropdown.id)} className="link" to={"/" + section.link + "/#" + linkName} >
                                                {dropdown.title}
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                            }
                        </li>
                    ))}
                </ul>
            </nav>
        </div>
    )

}

export default MobileHeader;