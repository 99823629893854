import React, { useState, useRef } from "react";

const PageNotFound = (props) => {
    return (
        <div>
            <p>The page you tried to access does not exist.</p>
        </div>
    )
}

export default PageNotFound;