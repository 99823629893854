import React from "react";
import ReactDOM from 'react-dom/client';

require('../css/mainStyles/main.css');

import App from "./App";

document.addEventListener("DOMContentLoaded", () => {
    ReactDOM.createRoot(document.getElementById('root')).render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
})