import React, { useState, useRef } from "react";

import Sidebar from "../Sidebar";

import Team from './Team';
import Facilities from "./Facilities";
import Observatories from "./Observatories";
import History from "./History";

const CapabilitiesPage = (props) => {
    return (
        <div className="content-container" >
            <Sidebar id="company-sidebar" shouldLoadDesktop={props.shouldLoadDesktop} sections={props.data} />
            <div className="content-sub-container" >
                <Team shouldLoadDesktop={props.shouldLoadDesktop} />
                <Facilities shouldLoadDesktop={props.shouldLoadDesktop} />
                <Observatories shouldLoadDesktop={props.shouldLoadDesktop} />
                <History shouldLoadDesktop={props.shouldLoadDesktop} />
            </div>
        </div>
    )
}

export default CapabilitiesPage;