import React, { useState, useRef } from "react";

import Sidebar from "../Sidebar";

require('../../css/news/news.css');

import Featured from "./Featured";
import News from "./News";
import Videos from "./Videos";
import Podcasts from "./Podcasts";
import Speaking from "./PublicAppearances";

const CapabilitiesPage = (props) => {
    return (
        <div className="content-container" >
            <Sidebar sections={props.data} id="company-sidebar" shouldLoadDesktop={props.shouldLoadDesktop} />
            <div className="content-sub-container" >
                <Featured shouldLoadDesktop={props.shouldLoadDesktop} />
                <News shouldLoadDesktop={props.shouldLoadDesktop} />
                <Videos shouldLoadDesktop={props.shouldLoadDesktop} />
                <Podcasts shouldLoadDesktop={props.shouldLoadDesktop} />
                <Speaking shouldLoadDesktop={props.shouldLoadDesktop} />
            </div>
        </div>
    )
}

export default CapabilitiesPage;