import {initializeApp} from "firebase/app"
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBBliCpoMsBsokUneK40pWZe8cb-7_Tm_Q",
  authDomain: "transastra-com.firebaseapp.com",
  projectId: "transastra-com",
  storageBucket: "transastra-com.appspot.com",
  messagingSenderId: "44952310952",
  appId: "1:44952310952:web:33aace7cb58825eaa25e9d"
};

// Initialize Firebase
const fb = initializeApp(firebaseConfig);
const db = getFirestore(fb);
export default db;