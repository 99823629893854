import React, { useState, useRef } from "react";

// import img from '../../assets/services/services header image.jpg';
import img from '../../assets/news/upcoming/cislunar_summit.png';
import img2 from '../../assets/news/upcoming/industry_days.png';
import img3 from '../../assets/news/upcoming/AMOS_2024.png';
import img4 from '../../assets/news/upcoming/WEF.png';

import img10 from '../../assets/news/logos/spacenews.png';
import img11 from '../../assets/news/logos/YahooFinance.png';
import img12 from '../../assets/news/logos/Quartz.png';
import img13 from '../../assets/news/logos/CNBC.png';
import img14 from '../../assets/news/logos/wall-street-journal.png';
import img15 from '../../assets/news/logos/POLITICOLOGO.png';
import img16 from '../../assets/news/logos/spacecom.png';
import img17 from '../../assets/news/logos/marketwatch.png';

const logos = [
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
]

require('../../css/news/header.css');

const bigFour = [
    {
        text: "AMOS 2024",
        img: img3,
        date: "SEPT 17 - 20, 2024",
        link: "https://amostech.com/timeline-post/2024/", 
    },
    {
        text: "Space Industry Days",
        img: img2,
        date: "OCT 23 - 24, 2024",
        link: "https://afcea-la.org/events/space-industry-days-2024-sponsorships/",  
    },
    {
        text: "World Economic Forum",
        img: img4,
        date: "NOV 12, 2024",
        link: "",
          
    },
    {
        text: "Cislunar Security Summit, JHU/APL",
        img: img,
        date: "DEC 4 - 6, 2024",
        link: "https://events.jhuapl.edu/event/8c1f0789-6780-4485-bcd6-c0d807461184/websitePage:b2a48061-80af-4661-ab2c-e7c49565276c",
        
    },
]


const Header = (props) => {
    let key1 = -1;
    let key2 = -1;
    return (
        <>
            <div className="news-header" >
                <div className="legend" >
                    <h1>Visit TransAstra at these upcoming events</h1>
                </div>
                <div className="headline-container" >
                    {bigFour.map((item) => {
                        ++key1;
                        return <a key={key1} target="_blank" href={`${item.link}`} >
                            <div className="img-container">
                                <img src={item.img}></img>

                            </div>
                            <div className="text" >
                                <p>{item.text}</p>
                                <p>{item.date}</p>
                            </div>
                        </a>
                    })}
                </div>

            </div>
            <div className="news-header" >
                <div className="logo-container" >
                    {logos.map((item) => {
                        ++key2;
                        return <div key={key2} >
                            <div className="img-container"><img src={item}></img></div>
                            <div className="dummy" ></div>
                        </div>
                    })}
                </div>
            </div>
        </>
    )
}

export default Header;