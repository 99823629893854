import React, { useState, useRef } from "react";

import img from "../../assets/capabilities/capture/capture-1.png";
import img2 from "../../assets/capabilities/capture/capture-2.png";
import img3 from "../../assets/capabilities/capture/capture-3.png"

import SelectionBar from "../SelectionBar";

const Capturing = (props) => {

    const [sectionNum, updateSectionNum] = useState(0);

    const setOne = <>
        <div className="left-set">
            <p>Flytrap—using rip-stop inflatable bags—encloses and secures uncooperative targets including cubesats, larger satellites, or even an entire rocket bodies.</p>
        </div>
        <div className="right-set" >
            <img src={img}></img>
        </div>
    </>

    const setTwo = <>
        <div className="left-set">
            <p>We can turn orbital debris into valuable resources. Recycling is 6 times cheaper than “de-orbiting” debris, uses 80% less propellant, and is 40% faster to clear a given orbit.</p>
        </div>
        <div className="right-set" >
            <img src={img2}></img>
        </div>
    </>

    const setThree = <>
        <div className="left-set">
            <p>Flytrap is a key component to our future asteroid mining plans. Flytrap will be able to capture asteroids the size of houses containing as much water as a swimming pool.</p>
        </div>
        <div className="right-set" >
            <img src={img3}></img>
        </div>
    </>

    const sets = [setOne, setTwo, setThree]
    const headers = ["Payloads for Host Spacecraft", "Cutting-Edge Remediation Approach", "Honey Bee™ Asteroid Mining Vehicle"]

    return (
        <div id="capturing" className="capa-container" >
            <h1>FLYTRAP™ CAPTURE BAG</h1>
            <p className="desc" >Flytrap capture bags are the most affordable way to clean up orbital debris and satellites at end of life. Flytrap can scale to work with objects of all shapes and sizes. Later we’ll use it to capture and mine asteroids.</p>
            <h1 className="sub-header" >{headers[sectionNum]}</h1>
            <div className="set-container" >
                {sets[sectionNum]}
            </div>
            <SelectionBar num={3} sectionNum={sectionNum} updateSectionNum={updateSectionNum} />

        </div>
    )
}

export default Capturing;