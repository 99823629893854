import React, { useRef, useState, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import Header from './navbars/Header';
import Footer from './navbars/Footer';

import SplashPage from "./splash/Container";
import Capabilities from "./capabilities/Container";
import Products from './products/Container';
import Company from './company/Container';
import News from './news/Container';
import Contact from './contact/Container';
import ScrollToTop from './scrollToTop';
import BrandPage from './brand/Container';

import PageNotFound from './PageNotFound';

require('../css/mainStyles/sidebar.css');
require('../css/mainStyles/selectionBar.css');
require('../css/mainStyles/capaContainer.css');

import data from './sections.json';



const AppShell = (props) => {
    const [shouldLoadDesktop, setShouldLoadDesktop] = useState(window.innerWidth > 1000 ? true : false);
    const isLoadingDesktop = useRef(shouldLoadDesktop);
    useEffect(() => {
        const resizeW = () => {
            // console.log(window.innerWidth, isLoadingDesktop);
            if (isLoadingDesktop.current && window.innerWidth < 1000) {
                isLoadingDesktop.current = false;
                setShouldLoadDesktop(false);
                return;
            };
            if (!isLoadingDesktop.current && window.innerWidth > 1000) {
                isLoadingDesktop.current = true
                setShouldLoadDesktop(true);
                return;
            }
        }
        window.addEventListener("resize", () => resizeW());

        return () => window.removeEventListener("resize", resizeW);
    }, []);

    return (<>
        <Header shouldLoadDesktop={shouldLoadDesktop} />
        <ScrollToTop />
        <Routes>
            <Route exact path="/" element={<SplashPage shouldLoadDesktop={shouldLoadDesktop} />} />
            <Route path={data.capabilities.link} element={
                <Capabilities data={data.capabilities} shouldLoadDesktop={shouldLoadDesktop} />}
            />
            <Route path={data.products.link} element={
                <Products data={data.products} shouldLoadDesktop={shouldLoadDesktop} />}
            />
            <Route path={data.company.link} element={
                <Company data={data.company} shouldLoadDesktop={shouldLoadDesktop} />}
            />
            <Route path={data.news.link} element={
                <News data={data.news} shouldLoadDesktop={shouldLoadDesktop} />}
            />
            <Route path={data.contact.link} element={
                <Contact data={data.contact} shouldLoadDesktop={shouldLoadDesktop} />}
            />
            <Route path="/brand-guidelines" element={<BrandPage />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
        <Footer />
    </>
    )
}

export default AppShell;