import React from "react";

import TKO from './TKO'
import AccuTilt from './AccuTilt'
import Theia from './Theia'

import Sidebar from "../Sidebar";

import { disableScroll } from '../scrollFuncs';

const fields = [
    { type: "Subject", html: "input" },
    { type: "Name", html: "input" },
    { type: "Organization", html: "input" },
    { type: "Email", html: "input" },
    { type: "Phone", html: "input" },
    { type: "Message", html: "textarea" },
]

const formIDs = [
    'TKO',
    'AccuTilt',
    'Theia'
]

const openForm = (formID) => {
    document.getElementById(`${formID}`).classList.toggle("hidden");
    disableScroll();
}

const CapabilitiesPage = (props) => {
    return (
        <div className="content-container" >
            <Sidebar id="prod-sidebar" shouldLoadDesktop={props.shouldLoadDesktop} sections={props.data} />
            <div className="content-sub-container" >
                <TKO openForm={openForm} fields={fields} formID={formIDs[0]} />
                <AccuTilt openForm={openForm} fields={fields} formID={formIDs[1]} />
                <Theia openForm={openForm} fields={fields} formID={formIDs[2]} />
                {/* <Systems /> */}
            </div>
        </div>
    )
}

export default CapabilitiesPage;