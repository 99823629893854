import React from "react";

import Header from "./Header";

import ContentContainer from "./ContentContainer";

const NewsPage = (props) => {
    return (
        <div id="company" >
            <Header shouldLoadDesktop={props.shouldLoadDesktop} linkID={props.data.dropdowns[0].id} />
            <ContentContainer data={props.data.dropdowns} shouldLoadDesktop={props.shouldLoadDesktop} />
        </div>
    )
}

export default NewsPage;