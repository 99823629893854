import db from "./firebase";
import { collection, addDoc } from "firebase/firestore";

export async function sendMessage(params) {
  console.log("Send Message Called");
  console.log("params: ", params);
//   return;
  try {
    const docRef = await addDoc(collection(db, "emails"), params);
    console.log("Email written with ID: ", docRef.id);
    return true;
    } catch (e) {
        console.error("Error adding document: ", e);
        alert("Server Error Sending Email");
        return false;
    }
}
