import React from "react";

import ContactForm from '../ContactForm';

import img from "../../assets/products/AccuTilt/AccuTilt-main.png";

const Mount = (props) => {

    const setOne = <>
        <ul className="left-set">
            <li>Direct X/Y adjustment with micrometer precision for repeatable adjustments</li>
            <li>Easy to use orthogonal kinematic adjustment design developed via field testing</li>
            <li>Real-time tilt correction using software assistance</li>
            <li>Designed for ease of assembly — camera can be mounted and removed quickly</li>
            <li>Unobstructed access to RASA collimation adjustment screws</li>
        </ul>
        <div className="right-set" >
            <img src={img} ></img>
        </div>
    </>

    return (
        <div id="mount" className="capa-container contactable" style={{ paddingTop: '3em' }}>
            <h1>AccuTilt™</h1>
            <p style={{ padding: "2em 0" }} >AccuTilt is an intuitive tip-tilt mount that will save you time anguish and improve image quality.
                <br></br>AccuTilt is designed for Celestron's RASA telescopes for precise focal plane parallelism, producing ultimate scientific imaging quality.</p>
            <h1 className="sub-header" style={{ "paddingBottom": "0.5em" }} >{"Key Features:"}</h1>
            <div className="set-container" >
                {setOne}
            </div>

            <div className="button" >
                <p onClick={() => { props.openForm(props.formID) }} >Click for Quote</p>
            </div>

            <div id={props.formID} className="hidden">
                <ContactForm formID={props.formID} useShader={true} title="AccuTilt Inquiry" fields={props.fields} />
            </div>
        </div>
    )
}

export default Mount;