import React, { useState, useRef, useEffect } from "react";
import emailjs from '@emailjs/browser';
import { options } from './contact/Form';
import { sendMessage } from "./mailFunctions";
import { enableScroll } from './scrollFuncs';

require('../css/mainStyles/form.css');

const closeForm = (formID) => {
    document.getElementById(`${formID}`).classList.add("hidden");
    enableScroll();
}

// Takes title, fields array, and options(?)
const Form = (props) => {

    const form = useRef();
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        const eles = document.getElementsByClassName("form-input");
        for (let i = 0; i < eles.length; i++) {
            eles[i].style.height = eles[i].scrollHeight + "px";
        }

        setTimeout(() => {
            props.options ? selectType(0) : document.getElementById("the-special-input").value = props.formID;
            // console.log('val', document.getElementById("the-special-input").value);
        }, 50)

    }, [])


    const sendMail = (e) => {
        e.preventDefault();
        const type = document.getElementsByClassName("options-container")[0].children[selected];
        console.log("Sent email called with form", form.current,
            "and type selection of", type.innerHTML);

        const option = options.filter((option) => option.name === type.innerHTML)[0]

        console.log(option)
        window.location.href = option.html

    }

    async function sendEmail(e) {
        e.preventDefault();
        const type = document.getElementsByClassName("options-container")[0].children[selected];
        // console.log("Sent email called with form", form.current,
        //     "and type selection of", type.innerHTML);
        const inputs = { ...form.current };
        let vals = {};
        for (let i = 0; i < props.fields.length; i++) {
            vals[props.fields[i].type] = inputs[i + 1].value;
        }
        vals.type = type.innerHTML;
        console.log("Email values:", vals);
        if (await sendMessage(vals)) {
            alert("Thank you for contacting TransAstra, we will get back to you soon.");
            e.target.reset();
            window.location = 'https://transastra.com/';
            const eles = document.getElementsByClassName("form-input");
            for (let i = 0; i < eles.length; i++) {
                eles[i].style.height = eles[i].scrollHeight + "px";
            }
        }
    }

    const sendEmailOld = (e) => {
        e.preventDefault();
        const type = document.getElementsByClassName("options-container")[0].children[selected];
        console.log("Sent email called with form", form.current,
            "and type selection of", type.innerHTML);
        //     // alert("Email sent successfully!");
        //     // document.getElementById("contact-form-form").reset();
        //     // e.target.reset();

        //     // return; // Remove when finished testing

        emailjs.sendForm('service_fld522c', 'template_ukvxaqp', form.current, '1NeMBYQiPISt4yXXm')
            .then((result) => {
                console.log(result.text);
                alert("Message successfully sent");
                e.target.reset();
                const eles = document.getElementsByClassName("form-input");
                for (let i = 0; i < eles.length; i++) {
                    eles[i].style.height = eles[i].scrollHeight + "px";
                }
            }, (error) => {
                console.log(error.text);
                alert("Message failed to send!");
            });
    };

    const textEntered = (ev, fieldType) => {
        const input = ev.currentTarget;
        const type = document.getElementById(props.formID + "-contact-form-" + fieldType);
        if (input.value !== "" && !type.classList.contains("text-entered")) {
            type.classList.add("text-entered");
        } else if (input.value === "" && type.classList.contains("text-entered")) {
            type.classList.remove("text-entered");
        }
        input.style.height = "auto";
        input.style.height = input.scrollHeight + "px";
    }

    const selectType = (num) => {
        setSelected(num);
        setTimeout(() => {
            const yes = document.getElementById("the-special-input");
            const no = document.getElementsByClassName("abcde")[0];
            yes.value = no.innerText;
        }, 50)
    }

    const optionsContainer = props.options ? <div className="options-container" >
        {props.options.map((option) => {
            let res = <div key={option.num} onClick={() => selectType(option.num)} >{option.name}</div>;
            if (option.num == selected)
                res = <div key={option.num} className="selected abcde" onClick={() => selectType(option.num)} >{option.name}</div>;

            return res;

        })}


    </div> :
        <></>

    let key1 = 1;
    return (
        <div id={props.formID + "-1"} className="contact-form" >
            {props.useShader ? <div className="test" onClick={() => { closeForm(props.formID) }} ></div> : <></>}
            <form ref={form} onSubmit={sendEmail} id={`${props.formID}-form`} >
                <h1>{props.title}</h1>
                {optionsContainer}
                <input id="the-special-input" onChange={() => { }} type="text" name="type" style={{ display: "none" }} />
                {
                    props.fields.map((field) => (
                        <label key={key1++} className="form-group" >
                            {
                                field.html === "input"
                                    ? <input onChange={(ev) => textEntered(ev, field.type)} type="text" name={field.type.toLocaleLowerCase()}
                                        className="form-input" required />
                                    : (<textarea rows="1" onChange={(ev) => textEntered(ev, field.type)} type="text" name={field.type.toLocaleLowerCase()}
                                        className="form-input" required />)
                            }
                            <span id={props.formID + "-contact-form-" + field.type} className="form-type" >{field.type}</span>
                            <span className="border" ></span>
                        </label>
                    ))
                }

                <button type="submit" value="Send" className="submit-button" >
                    Send Email <i className="zmdi zmdi-arrow-right"></i>
                </button>

            </form>
            {props.useShader ?
                <div onClick={() => { closeForm(props.formID) }} className="close" >X</div> :
                <></>
            }
        </div>
    )
}

export default Form;