import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';

require('../../css/splash/capabilities.css');

import detectImg from '../../assets/nebulas/Nebula-01.png';
import maneuverImg from '../../assets/nebulas/Nebula-01.png';
import captureImg from '../../assets/nebulas/Nebula-01.png';
import processImg from '../../assets/nebulas/Nebula-01.png';

// import detectImg from '../../assets/samples/sample1.jpg';
// import maneuverImg from '../../assets/samples/sample2.jpg';
// import captureImg from '../../assets/samples/sample3.jpg';
// import processImg from '../../assets/samples/sample4.jpg';

const text = [
    {
        id: 0,
        h1: "Detect",
        img: detectImg,
        h2: "SUTTER™ TELESCOPE TECHNOLOGY",
        p: `Our innovative Sutter Telescope system--named after Sutter's Mill in California, where gold was discovered in 1848--enables low-cost telescopes to spot asteroids and other dark, fast-moving objects in space`,
        linkID: 'detecting',
    },
    {
        id: 1,
        h1: "Move",
        img: maneuverImg,
        h2: "WORKER BEE™ POWERED BY OMNIVORE™",
        p: `Our patented Omnivore propulsion system can use almost any volatile liquid, like water, as propellant ensuring efficient spacecraft movement across vast distances in space.`,
        linkID: 'maneuvering',
    },
    {
        id: 2,
        h1: "Capture",
        img: captureImg,
        h2: "FLYTRAP™ CAPTURE BAG",
        p: `FlyTrap, a breakthrough innovation, will secure asteroids for mining and help clear Earth's orbits of dangerous debris. `,
        linkID: 'capturing',
    },
    {
        id: 3,
        h1: "Process",
        img: processImg,
        h2: "Optical Mining Process",
        p: `Optical Mining uses the Sun's power to extract resources, including water and precious metals, from asteroids. These methods will also be key to recycling orbital debris and cleaner recycling processes on Earth.`,
        linkID: 'processing',
    },
]

const text2 = [
    `The asteroids contain enough resources to support sustainable exponential human growth for a millennium without straining the Earth's biosphere. We see an abundant future in which humanity makes the bold leap to living, working, and thriving in space.`,
    `We've made groundbreaking inventions in four core capabilities vital for asteroid mining: Detect, Move , Capture, and Process. And we're building business around each layer of this tech stack. These capabilities serve government and commercial customers with solutions that they need now.`,
    
]

const ParaDisplay = () => (
    <div className="cab-blurb-container" >
        {text2.map((text) => (
            <h2 key={++key2} >{text}</h2>
        ))}
        
    </div>
)

const QuoteDisplay = () => (
    <div className="caba-blurb-container">
        <h2>
            "<i>Mindset</i> is even more important than technology in unleashing economic potential. If you believe in an unlimited future for your
            children and their children — that the world is getting better and will continue to do so— you're an optimist, and that is powerful.
            <br />The greatest economic benefit of space development will be optimism!"
            <br />- Dr. Joel Sercel, Founder and CEO
        </h2>

    </div>
)

let key2 = -1;
const Display = (props) => {
    return (
        <div className="display" >
            <h2>{text[props.num].h2}</h2>
            <p>{text[props.num].p}</p>
        </div>
    )
}

const Capabilities = (props) => {
    const [displayNumber, changeDisplayNumber] = useState(0);

    const scrollWithOffset = (eleID) => {
        setTimeout(() => {
            console.log("Scrolling to", eleID);
            const yCoordinate = document.getElementById(eleID).getBoundingClientRect().top + window.pageYOffset;
            const navbar = document.getElementsByClassName("head-container")[0];
            const yOffset = -navbar.offsetHeight;
            window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
        }, 75);
    }

    let dKey = -1;
    let mKey = -1;

    const desktopDisplay = <div id="splash-capabilities" className="splash-desktop splash-capabilities" >
        <ParaDisplay />
        <div><h1>Our Core Capabilities</h1></div>
        <div className="sub-container" >
            {text.map((section) => {
                ++dKey;
                const className = dKey === displayNumber ? "sub-sub-container selected" : "sub-sub-container";
                return (
                    <div className={className} key={dKey} onClick={() => changeDisplayNumber(section.id)} >
                        <div className="img-container" ><img src={section.img} ></img></div>
                        <p>{section.h1}</p>
                    </div>
                )
            })}
        </div>
        <Display num={displayNumber} />
        <div className="link-container" >
            <Link className="button"
                onClick={() => scrollWithOffset(text[displayNumber].linkID)}
                to={`/capabilities/#${text[displayNumber].linkID}`} >
                See More
            </Link>
        </div>
        <QuoteDisplay />
    </div>

    const mobileDisplay = <div id="splash-capabilities" className="splash-mobile splash-capabilities" >
        {/* <ParaDisplay /> */}
        <div><h1>Our Core Capabilities</h1></div>
        <div className="sub-container" >
            {text.map((section) => {
                ++mKey;
                const class2 = mKey === displayNumber ? "popup-container" : "popup-container hidden";
                const className = mKey === displayNumber ? "sub-sub-container selected" : "sub-sub-container";
                return (
                    <div key={mKey}>
                        <div className={className} onClick={() => changeDisplayNumber(section.id)} >
                            <div className="img-container" ><img src={section.img} ></img></div>
                            <p>{section.h1}</p>
                        </div>
                        <div className={class2} >
                            <div className="display" >
                                <h2>{section.h2}</h2>
                                <p>{section.p}</p>
                            </div>
                            <div className="link-container" >
                                <Link className="button"
                                    onClick={() => scrollWithOffset(text[displayNumber].linkID)}
                                    to={`/capabilities/#${text[displayNumber].linkID}`} >
                                    Learn More
                                </Link>
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    </div>

    return (
        <>
            {props.shouldLoadDesktop ? desktopDisplay : mobileDisplay}
        </>
    )
}
//Sutter telescope technology is a game-changer in detecting faint, moving objects throughout cislunar space and beyond
//Worker Bee spacecraft with Omnivore solar thermal propulsion is cheaper, faster, safer, and more sustainable than any other in-space transportation solution.
//Flytrap capture bags collect and safely contain objects in space for effective removal or relocation for reuse.
//We invented Optical Mining™ to use concentrated sunlight to mine asteroids. Today we are showing how this technology can recycle orbital debris.
export default Capabilities;