import React from "react";
import { useLocation, Link } from 'react-router-dom';

require('../../css/mainStyles/footer.css');

import logo from '../../assets/navbars/footer-logo.png';

import twitter from '../../assets/navbars/twitter-logo.png';
import linkedIn from '../../assets/navbars/linkedin-logo.png';
import youtube from '../../assets/navbars/youtube-logo.jpg';

const Footer = (props) => {

    // useEffect(() => {
    //     window.addEventListener('page-nav', footerAction);

    //     return window.removeEventListener('page-nav', footerAction);
    // }, [])

    const location = useLocation();

    const openLink = (url) => {
        window.open(url, '_blank');
    }

    return (
        <div id="footer-container" >
            <div id='footer-logo-and-copyright'>
                <img src={logo} />
                <p>13539 Desmond St, Los Angeles, CA 91331</p>
                <p>Copyright © 2017-2023 Trans Astronautica Corporation. All rights reserved.</p>
            </div>
            <div id="footer-button">
                {location.pathname !== '/contact-us' &&
                    <Link to="/contact">
                        Contact Us
                    </Link>
                }
                <div id="footer-link-container">
                    <div onClick={() => openLink("https://twitter.com/transastracorp?lang=en")} ><img src={twitter} /></div>
                    <div onClick={() => openLink("https://www.linkedin.com/company/trans-astronautica-corporation/")} ><img src={linkedIn} /></div>
                    <div onClick={() => openLink("https://www.youtube.com/@transastronauticacorporati5535")} ><img src={youtube} /></div>
                </div>
            </div>
        </div>
    );
};

export default Footer;