import React, { useState, useRef } from "react";
import { Link } from 'react-router-dom';

import img from "../../assets/capabilities/detect/detect-1.jpg";
import img2 from "../../assets/capabilities/detect/detect-2.png";

import SelectionBar from "../SelectionBar";

const scrollWithOffset = (eleID) => {
    setTimeout(() => {
        console.log("Scrolling to", eleID);
        if (eleID === "top") {
            window.scrollTo({ top: 0, behavior: "smooth" })
            return;
        }
        const yCoordinate = document.getElementById(eleID).getBoundingClientRect().top + window.pageYOffset;
        const navbar = document.getElementsByClassName("head-container")[0];
        const yOffset = -navbar.offsetHeight;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }, 75);
}

const Detecting = (props) => {

    const [sectionNum, updateSectionNum] = useState(0);

    const setOne = <>
        <div className="left-set" >
            <p>Our ground-based systems conduct nightly asteroid and spacecraft surveys and regularly report detections. <Link to='/products/#Turn-Key-Observatory' onClick={() => scrollWithOffset("observatory")} >Sutter TKO</Link> will provide next generation observation capability and close critical coverage gaps in ground-based SDA.</p>
        </div>
        <div className="right-set" >
            <img src={img}
                width="600"
                height="450" ></img>
        </div>
    </>
    const setTwo = <>
        <div className="left-set" >
            <p>With breakthrough computational efficiency, Theia runs on small on-board computers and can minimize downlink data rates by transmitting only detections.</p>
        </div>
        <div className="right-set" >
            <img src={img2}
                width="600"
                height="450" ></img>
        </div>
    </>

    const sets = [setOne, setTwo]
    const headers = ["Sutter Ground", "Sutter Space"]

    return (
        <div id="detecting" className="capa-container" >
            <h1>SUTTER™ TELESCOPE TECHNOLOGY</h1>
            <p className="desc" >With our patented <Link to ='/products/#Theia' onClick={() => scrollWithOffset("theia")} >Theia</Link> software, our Sutter telescopes can use inexpensive off the shelf hardware to find and track faint objects in space.</p>
            <h1 className="sub-header" >{headers[sectionNum]}</h1>
            <div className="set-container" >
                {sets[sectionNum]}
            </div>
            <SelectionBar num={2} sectionNum={sectionNum} updateSectionNum={updateSectionNum} />
        </div>
    )
}

export default Detecting;